import { clickApi, clickContact } from '@/utils/globalFunc'

export default {
  state: {
    menuList: {
      Platform: { name: 'Platform', index: 'Platform', linkUrl: 'https://platform.global-ptt.com/', isShow: true,showOnMobile: true , type: 'link'},
      Dispatch: { name: 'Dispatch', index: 'Dispatch', linkUrl: 'https://dispatch.global-ptt.com/', isShow: true,showOnMobile: true , type: 'link'},
      Radios: { name: 'Radios', index: 'Radios', linkUrl: 'https://txq-radio.com/', isShow: true ,showOnMobile: true,type: 'link'},
      Sims: { name: 'Sims', index: 'Sims', linkUrl: 'https://iot.global-ptt.com/#/searchindex', isShow: true ,showOnMobile: true, type: 'link'},
      Catalog: { name: 'Catalog', index: 'Catalog', linkUrl: '/global-ptt_product_catalog.pdf', isShow: true , showOnMobile: true, type: 'link',},
      Api: {
        name: 'Api',
        index: 'Api',
        linkUrl: '',
        isShow: true,
        showOnMobile: true,
        type: 'function',
        func: () => {
          clickApi('/apidoc.zip','The file will automatically download in 5 seconds.Contact us to obtain the file decompression password.');
        },
      },
      Contact: {
        name: 'Contact us',
        index: 'Contact',
        linkUrl: '',
        isShow: false,
        showOnMobile: true,
        type: 'function',
        func: () => {
          clickContact()
        },
      },
      GenericPlatform: {name: 'GenericPlatform', index: 'GenericPlatform', linkUrl: 'https://radio.top/', isShow: false, showOnMobile: false, type: 'link'},
      GenericDispatch: {name: 'GenericDispatch', index: 'GenericDispatch', linkUrl: 'https://dispatch.ltd/',isShow: false, showOnMobile: false, type: 'link'}
    },

    },
        contact: {
            email: 'aaa@abc.com',
            mobile: '+861234456'
    },
    getters: {
      getMenus: (state) => {
        let menus = Object.values(state.menuList).map((item) => ({
          name: item.name,
          index: item.index,
          linkUrl: item.linkUrl,
          isShow: item.isShow,
          showOnMobile: item.showOnMobile,
          type: item.type || 'link', // 默认类型为 "link"
          func: item.func,
        }));
        console.log('get mensu', menus)
        return menus
      }

  },
  mutations: {

  },
  actions: {
  },
    modules: {

  }
}