
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const clickApi = (fileUrl, alertMessage) => {
    setTimeout(() => {
        window.open(fileUrl, "_blank")
    }, 5000);
    ElMessage({
        message: alertMessage,
        type: "success",
        duration: 5000,
    });
}

const contactVisable = ref(false)

const clickContact = () => {
    contactVisable.value = true
}

export {
    clickApi,
    contactVisable,
    clickContact
}